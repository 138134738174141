<template>
  <div class="card">
    <div
      v-b-toggle="`collapseConferences`"
      class="card-header"
    >
      <h3
        :id="`field-4`"
        class="cart-title"
      >
        <a data-action="collapse">{{ field.label ? field.label : field.name }}</a>
      </h3>
      <b-tooltip
        :key="`field-custom-4`"
        placement="topleft"
        boundary="document"
        triggers="hover"
        :target="`field-4`"
        :title="field.tooltip"
      />
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
          <!-- <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              @click="redirectuser($event, [{user_id: userId}, 'researcher.conferences.index'])"
            ><i data-feather="edit-3" /></a>
          </li> -->
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseConferences`"
      v-model="isToggled"
    >
      <div
        class="card-content"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="row"
          >
            <div class="col">
              <ul
                v-for="conference, index in scientificConferences"
                :key="index"
                class="abridged-cv__list"
              >
                <li><strong>{{ conference.date_formatted }}</strong> ·
                  {{ conference.content_formatted.type ? `(${conference.content_formatted.type.name})` : '' }} · {{ conference.content ? `"${conference.content.title}"` : '---' }} {{ conference.content_formatted.virtual_activity ? '(online)' : '' }}
                  - {{ conference.event_name ? conference.event_name : '---' }}, {{ conference.country }} <a
                    class="ms-1 btn btn-sm bg-light-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                    @click="redirectuser($event, [{ id: conference.id }, 'researcher.conferences.edit'])"
                  ><i data-feather="edit-3" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
    BTooltip,
  },
  props: {
    userId: { type: Number, required: true, default: () => {} },
    yearBetween: { type: Object, required: true, default: () => {} },
    field: { type: Object, required: true },
    promo: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      loading: true,
      isToggled: true,
      type: 'conferences',
    }
  },
  computed: {
    ...mapGetters({
      scientificConferences: 'users/scientificConferences',
      toggled: 'preview/toggled',
    }),
  },
  watch: {
    userId() {
      this.reloadData()
    },
    toggled() {
      if (this.toggled !== this.isToggled) {
        this.isToggled = this.toggled
      }
    },
    yearBetween() {
      this.reloadData()
    },
  },
  async mounted() {
    this.reloadData()
  },
  methods: {
    async reloadData() {
      this.loading = true
      this.isToggled = this.toggled
      if (this.promo && this.promo.id && !this.promo.active_researcher) {
        await this.$store.dispatch('users/fetchUserScientificActivitiesFreezed', {
          convo: this.promo.id, userId: this.userId, yearBetween: this.yearBetween, type: this.type,
        })
      } else {
        await this.$store.dispatch('users/fetchUserScientificActivities', { userId: this.userId, yearBetween: this.yearBetween, type: this.type })
      }
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async redirectuser(event, redirection) {
      event.stopPropagation()
      this.$emit('goToIndex', redirection)
    },
  },
}
</script>

<style>

</style>
