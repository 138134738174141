<template>
  <div class="card">
    <div
      v-b-toggle="`collapseGrants`"
      class="card-header"
    >
      <h3
        :id="`field-9`"
        class="cart-title"
      >
        <a data-action="collapse">{{ field.label ? field.label : field.name }}</a>
      </h3>
      <b-tooltip
        :key="`field-custom-9`"
        placement="topleft"
        boundary="document"
        triggers="hover"
        :target="`field-9`"
        :title="field.tooltip"
      />
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
          <!-- <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              @click="redirectuser($event, [{user_id: userId}, 'researcher.grants.index'])"
            ><i data-feather="edit-3" /></a>
          </li> -->
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseGrants`"
      v-model="isToggled"
    >
      <div
        class="card-content"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="row"
          >
            <div class="col">
              <span
                v-for="grants, name in scientificGrants"
                :key="name"
              >
                <p class="badge bg-light-secondary mb-0">
                  {{ name }}
                </p>
                <hr>
                <span
                  v-for="grant, index in grants"
                  v-if="grant.length > 0"
                  :key="index"
                >
                  <p class="badge bg-light-info">
                    {{ index }}
                  </p>
                  <ul
                    v-for="minigrant, index2 in grant"
                    :key="index2"
                    class="abridged-cv__list"
                  >
                    <li>
                      <strong
                        :class="minigrant.from_personnel ? 'text-danger' : ''"
                      >{{ minigrant.begin_date_formatted }} - {{ minigrant.end_date_formatted ? minigrant.end_date_formatted : 'Present' }}</strong>
                      <template v-if="minigrant.grant_type_id != 4 && minigrant.grant_type_id != 16">
                        <span v-if="minigrant.funding_type_name">
                          · {{ minigrant.funding_type_name }}
                        </span>
                        <span v-if="minigrant.programme_name">
                          · {{ minigrant.programme_name }}
                        </span>
                        <span v-if="minigrant.title">
                          · "{{ minigrant.title }}"
                        </span>
                        <span v-if="minigrant.position_name">
                          · Role: <strong>{{ minigrant.position_name }}</strong>
                        </span>
                        <span v-if="minigrant.type_personnel_name">
                          · {{ minigrant.type_personnel_name }}
                        </span>
                        <span v-if="minigrant.total_amount">
                          · Total amount: <strong>{{ minigrant.total_amount }}€</strong>
                        </span>
                        <span v-if="minigrant.total_amount_group">
                          · Total amount given to your group: <strong>{{ minigrant.total_amount_group }}€</strong>
                        </span>
                      </template>
                      <template v-if="minigrant.grant_type_id == 16">
                        <span v-if="minigrant.funding_type_name">
                          · {{ minigrant.funding_type_name }}
                        </span>
                        <!-- <span v-if="minigrant.programme_name">
                        · {{ minigrant.programme_name }}
                      </span> -->
                        <span v-if="minigrant.type_personnel_name">
                          · {{ minigrant.type_personnel_name }}
                        </span>
                        <span v-if="minigrant.holder_name">
                          · {{ minigrant.holder_name }}
                        </span>
                        <span v-if="minigrant.dedication">
                          · {{ minigrant.dedication }}%
                        </span>
                      </template>
                      <template v-if="minigrant.custom_personnels.length > 0">
                        <a
                          class="ms-1 btn btn-sm bg-light-secondary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                          @click="redirectuser($event, [{id: minigrant.id}, 'researcher.grants.edit'])"
                        ><i data-feather="edit-3" /></a>
                        <div
                          v-for="personnel in minigrant.custom_personnels"
                          :key="personnel.id"
                          class="ps-1 pt-1"
                        >
                          · {{ personnel.from }} - {{ personnel.to ? personnel.to : 'Present' }} - {{ personnel.type_name }}{{ personnel.name ? ' - ' + personnel.name : '' }}
                        </div>
                      </template>
                      <a
                        v-if="minigrant.custom_personnels.length == 0"
                        class="ms-1 btn btn-sm bg-light-secondary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Edit"
                        @click="redirectuser($event, [{id: minigrant.id}, 'researcher.grants.edit'])"
                      ><i data-feather="edit-3" /></a>
                    </li>
                  </ul>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
    BTooltip,
  },
  props: {
    userId: { type: Number, required: true, default: () => {} },
    yearBetween: { type: Object, required: true, default: () => {} },
    promo: { type: Object, required: false, default: () => {} },
    field: { type: Object, required: true },
  },
  data() {
    return {
      loading: true,
      isToggled: true,
    }
  },
  computed: {
    ...mapGetters({
      scientificGrants: 'users/scientificGrants',
      toggled: 'preview/toggled',
    }),
  },
  watch: {
    userId() {
      this.reloadData()
    },
    toggled() {
      if (this.toggled !== this.isToggled) {
        this.isToggled = this.toggled
      }
    },
    yearBetween() {
      this.reloadData()
    },
  },
  async mounted() {
    this.reloadData()
  },
  methods: {
    async reloadData() {
      this.loading = true
      this.isToggled = this.toggled
      if (this.promo && this.promo.id && !this.promo.active_researcher) {
        await this.$store.dispatch('users/fetchUserScientificGrantsFreezed', { convo: this.promo.id, userId: this.userId, yearBetween: this.yearBetween })
      } else {
        await this.$store.dispatch('users/fetchUserScientificGrants', { userId: this.userId, yearBetween: this.yearBetween })
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async redirectuser(event, redirection) {
      event.stopPropagation()
      this.$emit('goToIndex', redirection)
    },
  },

}
</script>
